import React, { useState } from 'react';
import Section from '../Section';
import { Container, Row, Column } from '../Grid';
import Icon from '../Icon';
import Modal from '../Modal';
import './styles.scss';


const CardFlip = (props) => {

  const [animation, setAnimation] = useState(null);
  
  const handleFlipBack = (index) => {
    setAnimation(index);
  }

  const handleFlipFront = () => {
    setAnimation(null);
  }

  return (
    <Section className="pb-4">
      <Container>
        <Row>
          <Column size={10}>
            <h2>Explore data questions &amp; answers</h2>
            <p>Review questions and answers submitted by educators and data-curious community members from all over the country.</p>
          </Column>
        </Row>
        <Row>
          {props.expertList.map(({school, classroom, location, question, answer, author, career, company}, index) => (
            <Column size={3}>
              <div className="card-flip">
                <div className={`flip ${animation == index ? 'animation' : ''}`}>
                  <div className="front">
                    {/* front content */}
                    <div className="card">
                      <div className="card-block">
                        <h3 className="card-flip__title">Submitted question:</h3>
                        <p className="card-flip__question">{question}</p>
                        <div className="card-flip__btn-question">
                          <p>{school}</p>
                          <button onClick={() => handleFlipBack(index)}>
                            <span>See answer</span>
                            <div className="card-flip__icon-question">
                              <Icon name={'arrowright'}/>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="back">
                    {/* back content */}
                    <div className="card">
                      <div className="card-block">
                        <h3 className="card-flip__title">Answer:</h3>
                        <div className="card-block">
                          <p className="card-flip__answer">
                            {answer.length < 220 ? answer : <div className="card-flip__modal">
                              <p className="card-flip__modal-copy mb-0">{answer}</p>
                              <Modal
                                trigger={
                                  <span>Read More</span>
                                }
                              >
                                <h2>Question And Answer</h2>
                                <h3>Question</h3>
                                <h4>{question}</h4>
                                <div className="card-flip__modal-author">
                                  <em>{classroom}</em>
                                  <em>{school}</em>
                                  <em>{location}</em>
                                </div>
                                <h3>Answer</h3>
                                <p>{answer}</p>
                                <em>{author}</em>
                                <em>{career}</em>
                              </Modal>
                            </div>}
                          </p>
                          <div className="card-flip__author">
                            <p>{author}</p>
                            <p>{career}</p>
                            <p>{company}</p>
                          </div>
                          <div className="card-flip__btn-answer">
                          <button onClick={() => handleFlipFront(index)}>
                            <span>Return</span>
                            <div className="card-flip__icon-answer">
                              <Icon name={'arrowleft'}/>
                            </div>
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Column>
          ))}
          {/* End Card Flip */}
        </Row>
      </Container>
    </Section>
  );
};

export default CardFlip;