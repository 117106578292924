import React from 'react';
import Layout from '../../components/Layout';
import Callout from '../../components/Callout';
import Section from '../../components/Section';
import Image from '../../components/Image';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import CardFlip from '../../components/CardFlip';
import Subfooter from '../../components/Subfooter';
import { expertList } from '../../data/data-experts';
import Question from '../../components/Question';
import Modal from '../../components/Modal';
import AskExpertForm from '../../components/AskExpertForm';
import ScheduleAVisitForm from '../../components/ScheduleAVisitForm';

const textOverlay = (
  <div className="data-experts__text_overlay_container">
    <Container fullWidth>
      <div className="data-experts__text_overlay_info">
        Ask the data experts!
      </div>
    </Container>
  </div>
);

const subfooterContent = (
  <span>
    <span className="subfooter__highlighted-text">Data Science</span> is one of the twenty fastest growing occupations in the world.
  </span>
);

const DataExperts = () => {

  return (
    <Layout title="Data-Experts">
      <Hero
        mediaSource="img-lead-triangle-career-profiles.png"
        textOverlay={textOverlay}
      />
      <Section className="data-experts__callout-section section--gray">
        <Container fullWidth>
          <Row className="data-experts__callout-row">
            <Column size={12}>
              <Callout
                className="data-experts__callout"
                containerClassName="data-experts__callout-container"
              >
                Connecting the dots with data-driven answers
              </Callout>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 section--gray">
        <Container>
          <Row>
            <Column size={10}>
              <p className="text-sans-serif-large mb-2">
              Asking questions and following your curiosity are great ways to unpack the endless possibilities of data. Connect with a real-world volunteer Data Expert today to answer your questions about data and related careers. Submit your question below, and we’ll do our best to post an answer from one of our volunteers.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-4 section--gray">
        <Container>
          <Row>
            <Column size={4}>
              <Image filename={'career-submit-data.jpg'}/>
            </Column>
            <Column size={7}>
              <h2>Submit your question now!</h2>
              <p>
                Excited to learn more about what data is? Want to solve a problem using a data-driven approach? Curious about 21st Century careers in data and the skills to build? Ask away! Volunteer Data Experts will select original questions to be answered based on relevance to data topics and wider applicability.
              </p>
              {/* <Question
                trigger={
                  <Button className="ml-1"><Icon name={"externallink"} marginRight/>Submit your question</Button>
                }
              /> */}
              <Modal
                trigger={
                  <Button className="ml-1"><Icon name={"externallink"} marginRight/>Submit your question</Button>
                }
              >
                <AskExpertForm />
              </Modal>
            </Column>
          </Row>
        </Container>
      </Section>
      <CardFlip expertList={expertList} />
      <Section className="pt-0">
        <Container className={'career-profile__images'}>
          <Row className="mb-4">
            <Column size={4}>
              <Image filename="promo-request-a-visit@2x.jpg" />
            </Column>
            <Column size={8}>
              <h2 className="mb-0">
                Schedule a virtual classroom visit 
              </h2>
              <p className="text-sans-serif">
                Ready to take your real-world data explorations to the next level in class? If you are a teacher or afterschool program leader, you can request a visit with a Data Expert to bring hands-on interactive learning into any environment. Schedule a virtual classroom visit tailored to your students’ needs and curriculum.
              </p>
              <Modal
                trigger={
                  <Button>
                    <Icon name="externallink" />
                    <span className="ml-1">Request a visit</span>
                  </Button>
                }
              >
                <ScheduleAVisitForm />
              </Modal>
            </Column>
          </Row>
        </Container>
      </Section>
      <Subfooter
        img="footer-quote-career@2x.jpg"
        content={subfooterContent}
        icon="icon-subject.svg"
        subcontent="- U.S. Bureau of Labor Statistics"
      />
    </Layout>
  );
};

export default DataExperts;
